import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/Layout'

export default function PageGatsbyDevelopers(props: PageProps) {
  const { buildJson } = props.data as any
  const { title, description, devActions } = buildJson

  return (
    <Layout
      title={title}
      description={description}
      uri={props.uri}
      location={props.location}
      heroActions={devActions}
    >
      <br />
    </Layout>
  )
}

export const contentQuery = graphql`
  query BuildPageQuery {
    buildJson {
      title
      description
      devActions {
        title
        description
        url
      }
    }
  }
`
